@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.css';
@import '~antd/dist/antd.less';

@import '~swiper/swiper-bundle.css';

p {
  margin-bottom: 0;
}

