@font-face {
  font-family: 'DF-SouGei-W5';
  src: url('./DF-SouGei-W5.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Super-Cell';
  src: url('./Super-Cell.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  color: #a8aeba;
  background-color: #fff;
  font-size: 14px;
  font-family: 'DF-SouGei-W5', sans-serif;
  font-feature-settings: 'palt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

body.en {
  font-family: 'DF-SouGei-W5', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'DF-SouGei-W5', sans-serif;
  }
}

body.ja {
  font-family: 'DF-SouGei-W5', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'DF-SouGei-W5', sans-serif;
  }
}

body.zh {
  font-family: 'DF-SouGei-W5', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'DF-SouGei-W5', sans-serif;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.application {
  width: 100%;
  min-height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

&::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

&::-webkit-scrollbar-track {
  background: #f8f8f8;
  transition: all 0.2s;
}

&::-webkit-scrollbar-thumb {
  background: #8a8aa0;
  border-radius: 100px;
  transition: all 0.2s;
}

&::-webkit-scrollbar-thumb:hover {
  background: #8a8aa0;
  transition: all 0.2s;
}

.sortby-dropdown {
  .ant-dropdown-menu-item {
    padding: 0;
    cursor: not-allowed;

    .menu-item {
      padding: 5px 12px;
      cursor: pointer;

      &.active {
        user-select: none;
        pointer-events: none;
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
}

.modal-galley-item {
  width: 100% !important;
  height: 100%;
  transform-origin: unset !important;
  padding: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  position: fixed;
  z-index: 999999;
  background-color: rgba(18, 19, 26, 0.7);

  .ant-modal-content {
    background: transparent;
    border-radius: 0 !important;
    box-shadow: none;

    .ant-modal-close {
      right: 20px;
      background: transparent;

      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        .icon-close {
          width: 22px;
          height: 24px;
        }
      }
    }

    .ant-modal-body {
      background: transparent;

      .swiper-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
          background-color: #fff100;
        }

        .swiper-button-next,
        .swiper-button-prev {
          color: #fff100;
        }

        .swiper-pagination-bullet-active {
          background-color: #fff100;
          color: #fff100;
        }

        .swiper-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          transition-property: transform;
          box-sizing: content-box;
          align-items: center;

          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;

            .thumbnail-image {
              max-height: 80vh;
              max-width: 70vw;
              min-height: 70vh;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-galley-item {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          font-size: 20px;
        }
      }

      .ant-modal-body {
        .swiper-container {
          .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
            background-color: #fff100;
          }

          .swiper-button-next,
          .swiper-button-prev {
            color: #fff100;

            &::after {
              font-size: 25px;
            }
          }

          .swiper-pagination-bullet-active {
            background-color: #fff100;
            color: #fff100;
          }
        }
      }
    }
  }
}

// swiper
@themeColor: #3cc8fc;
/*
:root {
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
*/

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;

  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }

  .swiper-pagination-bullet-active {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }

  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);

  button& {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }

  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);

  .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
    display: block;
  }

  &.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;

    .swiper-pagination-bullet {
      display: inline-block;
      transition: 200ms transform, 200ms top;
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  }

  &.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    .swiper-pagination-bullet {
      transition: 200ms transform, 200ms left;
    }
  }
}

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;

  .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }

  .swiper-rtl & .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }

  .swiper-horizontal > &,
  &.swiper-pagination-horizontal,
  .swiper-vertical > &.swiper-pagination-progressbar-opposite,
  &.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-vertical > &,
  &.swiper-pagination-vertical,
  .swiper-horizontal > &.swiper-pagination-progressbar-opposite,
  &.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.swiper-pagination-lock {
  display: none;
}

.sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }

  .sp {
    display: block !important;
  }
}

#web-body {
  padding-top: 0;
}

.transfer-icon {
  filter: brightness(0) invert(1);
}

.ant-dropdown-menu {
  box-shadow: 0px 0px 10px rgba(232, 189, 219, 0.3) !important;
  background-color: #1b1e24 !important;
  border-radius: 10px 10px 10px 10px !important;
  .ant-dropdown-menu-item {
    background-color: transparent !important;
  }
  .ant-dropdown-menu-title-content {
    padding: 2px 5px;
    color: #fff !important;
  }

  .ant-dropdown-menu-title-content:hover {
    background-color: #5A5D73 !important;
    border-radius: 10px 10px 10px 10px;
  }
}

.ant-dropdown-menu.menu-category li {
  color: #fff !important;
}

::selection {
  background: #3cc8fc !important;
}
.ant-select-item {
  &:hover {
    background-color: #d6d6d6;
  }
}
.ant-select-item-option-content {
  color: #282828;
  &:hover {
    opacity: 0.5;
  }
}

.ant-popover {
  border-radius: 8px !important;
}
.ant-popover-arrow-content {
  margin: 0;
  box-shadow: 0px 4px 100px 0px #93989a66 !important;

  &::before {
    background-color: #ffffff !important;
  }
}

.ant-popover-content {
  box-shadow: 0px 4px 100px 0px #93989a66 !important;
  background-color: #ffffff;
  border-radius: 10px;

  .ant-popover-inner-content {
    padding: 4px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 100px 0px #93989a66 !important;
    border: 1.5px solid #dbdbdb;
  }
  .ant-popover-inner {
    background-color: transparent;
  }
}

.ant-dropdown {
  background-color: #1b1e24 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.language-dropdown {
  position: fixed !important;
}

.ant-select-selector {
  border: none;
  box-shadow: none;
  background-color: transparent !important;
  .ant-select-selection-item {
    color: #011e5a;
  }
  .ant-select-selection-placeholder {
    color: #a8aeba;
  }
  .ant-select-arrow {
    color: #fff;
  }
}
.ant-select-dropdown {
  padding: 20px;
  background: #ffffff !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  // min-width: 250px !important;
  .ant-select-item,
  .ant-select-item-option,
  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: transparent !important;
  }
  .ant-select-item-option {
    font-family: 'DF-SouGei-W5', sans-serif;
    .ant-select-item-option-content {
      margin-left: 10px;
      color: #011e5a !important;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    &::before {
      border: 1px solid #045afc;
      background-color: #045afc;
    }
  }
  .ant-select-item-option-active {
    background-color: transparent;
    .ant-select-item-option-content {
      background-color: transparent;
      // color: #045afc !important;
      font-weight: 400;
      font-size: 14px;
    }
  }
  // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   box-shadow: none !important;
  //   border: none !important;
  // }
}

.container {
  max-width: 1440px;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-detail {
  .ant-popover-content,
  .ant-popover-inner,
  .ant-popover-inner-content {
    border-radius: 20px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.modal-gacha-1 {
  .customer-modal {
    .ant-modal-content {
      max-width: 100%;
      box-shadow: 0px 0px 6px 0px #00000040;

      .ant-modal-close {
        right: 15px;
        top: 15px;

        .ant-modal-close-x {
          height: 24px;
          width: 24px;

          .anticon-close {
            font-size: 24px !important;
          }
        }

        @media screen and (max-width: 767px) {
          right: 5px;
          top: 5px;

          .ant-modal-close-x {
            height: 20px;
            width: 20px;

            .anticon-close {
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}

.max-height {
  &.ant-spin {
    max-height: 100% !important;
  }
}

.button-image {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  outline: none !important;
}
